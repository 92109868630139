<template>
    <!-- Login-->
        <p class="card-text mb-2 pt-2"><b>Forgotten your password? Nothing to worry about </b></p>
        <div v-if="!isProcessed">
            <div v-if="tokenData">
                <!-- <h2 class="card-title fw-bold mb-1">Welcome to Sabenzi! 👋</h2> -->
                <form class="auth-login-form mt-2" action="#" @submit.prevent="createLogin">
                    <div class="mb-1">
                        <label class="form-label" :class="fv.email.msg?'text-danger':''" for="fv_email">
                            Email
                            <span v-if="fv.email.msg" > | {{fv.email.msg}}</span>
                        </label>
                        <input class="form-control" id="fv_email" type="email" required v-model.trim="fv.email.val"
                               @keyup="validate.validateFormData(fv,'email')" placeholder="Enter email" aria-describedby="login-email"
                                :class="fv.email.msg?'border-danger':''" autofocus="" tabindex="1" />
                    </div>

                    <div class="mt-1">
                        <base-captcha :key="captcha" :captchaVal="captcha" ></base-captcha>
                    </div>
                    <div >
                        <label v-if="fv.captcha.msg" class="form-label text-danger" >
                            {{fv.captcha.msg}}
                        </label>
                        <input class="form-control"  type="text"
                        required v-model="fv.captcha.val"  min="7" max="7" @keyup="validate.validateFormData(fv,'captcha')"
                        placeholder="Enter the text above" aria-describedby="captcha" autofocus="" tabindex="1" />
                    </div>


                    <div class="col-12" v-if="fv.reqFeedback.status">

                        <base-list-tag class="mt-1" :tagColor="fv.reqFeedback.val" >{{fv.reqFeedback.msg}}</base-list-tag>
                    </div>

                    <div >
                        <base-button btnColor="primary" class="w-100 mt-2" tabindex="4" v-if="fv.showSubmit.val">
                            Login
                        </base-button>
                        <base-spinner  v-else></base-spinner>
                    </div>

                </form>

                <p class="text-center mt-2">
                    <router-link to="/login" >
                        <span> <i class="bi bi-chevron-left"></i> &nbsp;  Back to login </span>
                    </router-link> |
                    <router-link to="/sign_up" >
                        <span>   Create account <i class="bi bi-chevron-right"></i> </span>
                    </router-link>
                </p>

            </div>

            <base-card-response  v-else>

                <template #header></template>
                <template #default>
                    <p>
                        {{reqError}}
                    </p>
                </template>

            </base-card-response>
        </div>

        <base-card-response  cardType="success" v-if="isProcessed">

            <template #header>Your request has been processed. </template>
            <template #default>
                <p>
                    If your email is valid you will recieve an email on how to reset your password.
                </p>
                <p><router-link to="/login"> <u style="color:#fff"> Go back to login </u></router-link></p>
            </template>

        </base-card-response>
    <!-- End Login-->

</template>

<script>
import { ref,reactive,inject } from 'vue'
import validators from '@/hooks/validation'

export default{

  name: 'CreateAuthSignUp',
  setup() {

    // get the validators
    const validate = validators();

    // get the axios object
    const axios = inject('axios');  // inject axios

    //get the urls
    const coreUrl = inject('coreUrl');

    // initiate token, csrf_token and captcha variables with blank values
    let token = "";
    let csrf_token = "";
    const captcha = ref("");

    const tokenData  = ref(false);
    const reqError  = ref("Loading login form....");



    // when the vue module is created, get the token value, using the try catch

            // use axios to get the data
            axios.get(coreUrl.backendUrl+'get_csrf_token',{withCredentials: true}).then((response) =>{
                // return the full token to the token variable
                token = response.data.data;
                tokenData.value = true;

                // make the first 43 characters, the csrf token
                csrf_token = token.substring(0,43)

                // make the last 5 characters, the captcha
                captcha.value = token.substring(43,48)


            }).catch((error)=>{
                // if an error occours, do this
                tokenData.value = false;
                const errorMsg = "Login form can't be loaded at this time. Try again";
                reqError.value = error.response.data.message.req_msg?errorMsg:errorMsg;
            })




     // create variables for the values to be submitted
    const fv = reactive({
        email : {status:false, val:"", msg:false, validate:["required","email"]},
        password : {status:false, val:"", msg:false, showPassword: false, validate:["required",
                                                                                      {option:"textMin",val:10},
                                                                                      {option:"textMax",val:64}
                                                                                     ]},
        captcha : {status:false, val:"", msg:false, validate:["required","alphaNumeric"]},
        reqFeedback : {status:false, val:"", msg:false},
        showSubmit : {status:false, val:true}
    })


    // toggle password visibility
    function togglePasswordVisibility(){
        fv.password.showPassword = !fv.password.showPassword;
    }

    // get the current status of the created account
    const isProcessed = ref(false);

    // create the account using async
    async function createLogin(){

        fv.showSubmit.val = false;

        // load the form data , and append all the necessary fields
        let formData = new FormData();

        formData.append("fv_email", fv.email.val);
        formData.append("fv_host_root_url", coreUrl.tmsUrl);
        formData.append("fv_csrf_token",csrf_token+fv.captcha.val);
        formData.append("fv_account_type","organization");

        // post the data using axios
        try{

            const response = await axios.post(
                coreUrl.backendUrl+'create_forgot_password_reset',
                formData,
                {
                    withCredentials: true,
                    headers:{
                       "Content-Type": "multipart/form-data"
                    }
                }
            );

            // If successful, get the resppnse data

            isProcessed.value = response.data.status;

            fv.reqFeedback.status = false;

            fv.showSubmit.val = true;



        }catch(error){
            // redo the captcha sequence
            token =  error.response.data.data?error.response.data.data:"bad";
            csrf_token =  error.response.data.data?token.substring(0,43):"bad";
            captcha.value =  error.response.data.data?token.substring(43,48):"bad";

            // load the error to the appropriate field
            const errorMessages = error.response.data.message;

            for(const key in errorMessages){
                if(fv[key]){
                    fv[key]["status"] = false;
                    fv[key]["msg"] = error.response.data.message[key];
                }
            }


            fv.reqFeedback.status = true;
            fv.reqFeedback.msg = errorMessages["reqFeedback"]?errorMessages["reqFeedback"]:"Your request cannot be processed at this time. Kindly try again";
            fv.reqFeedback.val = "danger";
            fv.showSubmit.status = false;
            fv.showSubmit.val = true;

            // end load the error to the appropriate field

        }

     }



    return {
        fv,
        validate,
        tokenData,
        reqError,
        togglePasswordVisibility,
        createLogin,
        captcha,
        isProcessed
     }
  }
}
</script>
